<script>
const contentCols = [
  {show:false,prop:'0',label:'正常'},
  {show:false,prop:'G',label:'正常(效期短)'},
  {show:false,prop:'F',label:'正常(未貼中標)'},
  {show:false,prop:'E',label:'正常(版本不同)'},
  {show:false,prop:'B',label:'正常(縮膜)'},
  {show:false,prop:'A',label:'良品(退貨)'},
  {show:false,prop:'A2',label:'良品(拒收)'},
  {show:false,prop:'PC1',label:'良品(PC退貨)'},
  {show:false,prop:'MO1',label:'良品(mo退貨)'},
  {show:false,prop:'MO2',label:'良品(momotv退貨)'},
  {show:false,prop:'SH1',label:'良品(蝦皮退貨)'},
  {show:false,prop:'SH2',label:'良品(蝦皮拒收)'},
  {show:false,prop:'DON1',label:'良品(東森退貨)'},
  {show:false,prop:'DON2',label:'良品(東森拒收)'},
  {show:false,prop:'C',label:'正常(康是美)'},
  {show:false,prop:'COS0',label:'正常(康是美贈品/試用品)'},
  {show:false,prop:'COS1',label:'良品(康是美退貨)'},
  {show:false,prop:'STORE1',label:'良品(店鋪退貨)'},
  {show:false,prop:'STORE2',label:'良品(店鋪拒收)'},
  {show:false,prop:'WAT1',label:'良品(屈臣氏退貨)'},
  {show:false,prop:'WAT2',label:'良品(屈臣氏拒收)'},
  {show:false,prop:'HANDS1',label:'良品(台隆退貨)'},
  {show:false,prop:'SAPPD1',label:'良品(札幌退貨)'},
  {show:false,prop:'TOMOD1',label:"良品(Tomod's退貨)"},
  {show:false,prop:'MATSU1',label:'良品(松本清退貨)'},
  {show:false,prop:'POYA1',label:'良品(寶雅退貨)'},
  {show:false,prop:'B',label:'不良品'},
  {show:false,prop:'E',label:'不良品(版本不同)'},
  {show:false,prop:'A1',label:'不良品(退貨)'},
  {show:false,prop:'A2',label:'不良品(拒收)'},
  {show:false,prop:'PC1',label:'不良品(PC退貨)'},
  {show:false,prop:'MO1',label:'不良品(mo退貨)'},
  {show:false,prop:'MO2',label:'不良品(momotv退貨)'},
  {show:false,prop:'SH1',label:'不良品(蝦皮退貨)'},
  {show:false,prop:'SH2',label:'不良品(蝦皮拒收)'},
  {show:false,prop:'DON1',label:'不良品(東森退貨)'},
  {show:false,prop:'DON2',label:'不良品(東森拒收)'},
  {show:false,prop:'COS1',label:'不良品(康是美退貨)'},
  {show:false,prop:'STORE1',label:'不良品(店鋪退貨)'},
  {show:false,prop:'STORE2',label:'不良品(店鋪拒收)'},
  {show:false,prop:'WAT1',label:'不良品(屈臣氏退貨)'},
  {show:false,prop:'HANDS1',label:'不良品(台隆退貨)'},
  {show:false,prop:'SAPPD1',label:'不良品(札幌退貨)'},
  {show:false,prop:'TOMOD1',label:"不良品(Tomod's退貨)"},
  {show:false,prop:'MATSU1',label:'不良品(松本清退貨)'},
  {show:false,prop:'POYA1',label:'不良品(寶雅退貨)'},
  {show:false,prop:'C',label:'正常(效期短)'},
  {show:false,prop:'A',label:'正常(未貼中標)'},
  {show:false,prop:'D',label:'正常(康是美贈品/試用品)'},
]
import { Container, Header, Main, Row, Col, Table, TableColumn, Button, Checkbox, Select, Option, Divider, RadioGroup, RadioButton, DatePicker, Message,Tooltip,CheckboxGroup,Input } from 'element-ui'
import { Mixin } from '@/mixins/util.js'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
export default {
  name: 'dispatch',
  mixins: [Mixin],
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-container': Container,
    'el-header': Header,
    'el-main': Main,
    'el-row': Row,
    'el-col': Col,
    'el-checkbox': Checkbox,
    'el-checkbox-group': CheckboxGroup,
    'el-select': Select,
    'el-option': Option,
    'el-divider': Divider,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-date-picker': DatePicker,
    'el-tooltip': Tooltip,
    'el-input': Input,
  },
  data() {
    return {
      sessionid:localStorage.getItem('ac'),
      cols:[
        {key:'shipping_date',label:'日付',width:100,align:'center'},
        {key:'move_type',label:'移動類型',width:180,align:'center'},
        {key:'move_target',label:'移動目的地',width:120,align:'center'},
        {key:'title',label:'移動標題'},
      ],
      height:'75vh',
      dipData:null,
      pick_date:null,
      move_type1:true,
      move_type2:true,
      move_type3:true,
      p_type:['2','3','4','5'],
      showFilterArea:true,
      pickerOptions: {
          shortcuts: [
            {
              text: '本月',
              onClick(picker) {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth() , 1);
                const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '前月',
              onClick(picker) {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                const end = new Date(now.getFullYear(), now.getMonth() - 1 + 1, 0);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '前前月',
              onClick(picker) {
                const now = new Date();
                const start = new Date(now.getFullYear(), now.getMonth() - 2, 1);
                const end = new Date(now.getFullYear(), now.getMonth() - 2 + 1, 0);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '過去7日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '過去14日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '過去30日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
              }
            }, 
            {
              text: '過去60日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: '過去90日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: '過去180日',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                picker.$emit('pick', [start, end]);
              }
            },
            {
              text: '過去一年',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                picker.$emit('pick', [start, end]);
              }
            }
          ]
      },
      dip_type:{
        '0':'其他',
        '1':'出庫',
        '2':'入庫',
      },
      move_type_dispatch_table:{
        '101':'サンプル出貨',
        '102':'補寄',
        '103':'換貨',
        '104':'モール',
        '105':'卸(買取)',
        '106':'東京西川',
        '107':'其他',
        '108':'他社員購',
        '109':'越境販賣',
        '115':'卸(委託)',
        '117':'倉庫內移動(正常→不良)',
        '119':'倉庫內移動(良→不良)',
        '121':'移動',
        '300':'倉庫内ステータス変更',
      },
      move_type_warehouse_table:{
        '001_000036_000_00000000':'SHOPEE',
        '001_000043_000_00000000':'ETMALL',
        '001_000048_000_00000000':'FRIDAY',
      },
      move_type_retail_table:{
        '1':'リテール出荷',
        '3':'リテール返品',
      },
      move_target_table:{
        '1':'YUSEN',
        '2':'宅配通',
        '3':'越境倉庫',
        '4':'MOMO',
        '5':'YAHOO',
        '6':'PChome',
        '7':'台隆手創館',
        '8':'日藥本舖',
        '9':'其他',
        '10':'京站',
        '11':'長榮',
        '12':'plainme'
      },
      dispatch_options:[],
      warehouse_options:[],
      retail_options:[],
      curPage: 1,
      pageSize: 50,
      pagerCount: 11,
      page_size_options:[50,100,150],
      stateList:[],
      filter_title:'',
      contentCols
    }
  },
  computed:{
    filter_type_list(){
      return this.stateList
        .filter(d=>this.p_type.includes(d.product_type))
        .map(d=>d.product_code)
    },
    dispatch(){
      if(this.dipData===null) return []
      if(!this.move_type1) return []
      return this.dipData.dispatch
        .map(d=>{
            const raw_content = JSON.parse(d[5])
            let contentCols = []
            let content = null
            if(this.sessionid === 'shinnihon'){
              content = raw_content.map(d=>{
                let c = {code:d.code}
                for (const quality of ['qualified_quantity','defective_quantity']) {
                  let prop = null
                  if(d[quality].includes('_')){
                    //new version, get type char before '_' like '0_99', 0 is type , 99 is quantity
                    prop = d[quality].split('_')[0]
                    c[prop] = d[quality].split('_')[1] * 1
                  }else{
                    //old version, get first char
                    if(d[quality].length>1){
                      prop = d[quality].slice(0,1)
                      c[prop] = d[quality].slice(1) * 1
                    }
                  }
                  if(prop!==null){
                    const colIndex = contentCols.findIndex(col=>col.prop === prop)
                    if(colIndex===-1) {
                      const label = this.contentCols.find(col=>col.prop === prop).label
                      contentCols.push({prop,label})
                    }
                  }
                }
                return c
              })
            }
            else {
              contentCols = [{prop:'qualified_quantity',label:'良'},{prop:'defective_quantity',label:'不良'}]
              content = raw_content.map(d=>{
                let c = {code:d.code}
                for (const quality of ['qualified_quantity','defective_quantity']) {
                  if(d[quality].includes('_')){
                  c[quality] = d[quality].split('_')[1]*1
                }else {
                    c[quality] = d[quality] * 1
                  }
                }
                return c
              })
            }
            return {
                  from:'dispatch',
                  dip_type:this.dip_type[d[0]],
                  shipping_date:d[1],
                  type:d[2],
                  move_type:this.move_type_dispatch_table[d[2]],
                  move_target:this.move_target_table[d[3]],
                  title:d[4],
                  content,
                  contentCols
                }
        })
    },
    warehouse(){
      if(this.dipData===null) return []
      if(!this.move_type2) return []
      return this.dipData.warehouse_delivery
        .map(d=>{
            return {
                  from:'warehouse',
                  dip_type:this.dip_type[d[0]],
                  shipping_date:d[1],
                  type:d[2],
                  move_type:this.move_type_warehouse_table[d[2]],
                  move_target:d[3],
                  title:d[4],
                  contentCols : [{prop:'qualified_quantity',label:'良'},{prop:'defective_quantity',label:'不良'}],
                  content:d[5].map(d=>{
                    if(d.state===1) {
                      d.qualified_quantity = d.quantity*1
                      d.defective_quantity = 0
                    }
                    else{
                      d.qualified_quantity = 0
                      d.defective_quantity = d.quantity*1
                    }
                    return d
                  }),
                }
        })
    },
    retail(){
      if(this.dipData===null) return []
      if(!this.move_type3) return []
      return this.dipData.retail
        .map(d=>{
            const res = {
              from:'retail',
              dip_type:this.dip_type[d[0]],
              shipping_date:d[1],
              type:d[2],
              move_type:this.move_type_retail_table[d[2]],
              move_target:d[3],
              title:d[4].split('_')[0],
              contentCols : [{prop:'qualified_quantity',label:'良'},{prop:'defective_quantity',label:'不良'}]
            }
            if(res.dip_type==='出庫'){
              res.content = JSON.parse(d[5]).map(d=>{
                const set_quantity = d.quantity*1
                const [code,quantity] = d.code.split('*')
                d.code = code
                if(d.quality==='無品級'){
                  d.qualified_quantity = quantity*1*set_quantity
                  d.defective_quantity = 0
                }else if(d.quality.includes('良品') ||d.quality.includes('正常')){
                  d.qualified_quantity = quantity*1*set_quantity
                  d.defective_quantity = 0
                }else if(d.quality.includes('不良品')){
                  d.qualified_quantity = 0
                  d.defective_quantity = quantity*1*set_quantity
                }
                return d
              })
            }else if(res.dip_type==='入庫'){
              res.content = JSON.parse(d[5]).map(d=>{
                if(d.state.includes('良品')){
                  d.qualified_quantity = d.quantity*1
                  d.defective_quantity = 0
                }else if(d.state.includes('不良')){
                  d.qualified_quantity = 0
                  d.defective_quantity = d.quantity*1
                }
                return d
              })
            }
            return res
        })
    },
    filter_dispatch(){
      const options = this.dispatch_options.reduce((acc,cur)=>{
        if(cur.checked) acc.push(cur.label)
        return acc
      },[])
      return this.dispatch.filter(d=>options.includes(d.move_type))
    },
    filter_warehouse(){
      const options = this.warehouse_options.reduce((acc,cur)=>{
        if(cur.checked) acc.push(cur.label)
        return acc
      },[])
      return this.warehouse.filter(d=>options.includes(d.move_type))
    },
    filter_retail(){
      const options = this.retail_options.reduce((acc,cur)=>{
        if(cur.checked) acc.push(cur.label)
        return acc
      },[])
      return this.retail.filter(d=>options.includes(d.move_type))
    },
    union_data(){
      return  [...this.filter_dispatch,...this.filter_warehouse,...this.filter_retail]
        .sort((a,b)=>new Date(b.shipping_date) - new Date(a.shipping_date))
    },
    union_data_with_filter_title(){
      if(this.filter_title==='') return this.union_data
      return this.union_data.filter(d=>d.title.includes(this.filter_title))
    },
    union_data_with_filter_type(){
      if(sessionStorage.getItem('session_id')==='3294228999') {
        return this.union_data_with_filter_title
        .map(d=>{
          d.content = d.content.map((c,i)=>{
            c.code = `OOO_${i+1}`
            return c
          })
          return d
        })
      }
      if(this.filter_type_list.length === 0) return this.union_data_with_filter_title
      
      return this.union_data_with_filter_title.filter(d=>{
        let isFind = false
        for (const item of d.content) {
          if(this.filter_type_list.includes(item.code))
            isFind = true
        }
        return isFind
      })
    },
    union_data_with_pager(){
      return this.union_data_with_filter_type
        .slice(
          this.pageSize * (this.curPage - 1),
          this.pageSize * this.curPage
        )
    },
    headerlist(){
      // [
      //   [code1,code2,...],
      //   [code1良,code2良,...],
      //   [code1不良,code2不良,...]
      // ]

      return this.union_data_with_filter_type.reduce((a,c)=>{
        for (const content of c.content) {
          const index = a[0].findIndex(code=>code === content.code)
          if(index === -1) {
            a[0].push(content.code)
            a[1].push(content.qualified_quantity)
            a[2].push(content.defective_quantity)
          }else{
            a[1][index] += content.qualified_quantity
            a[2][index] += content.defective_quantity
          }
        }
        return a
      },[[],[],[]])
    },
  },
  methods:{
    async getDipData(){
      if(this.pick_date === null) {
        Message.error('Please select date')
        return
      } 
      const [begin_date,end_date] = this.pick_date
      const move_type = this.move_type1 * 4 + this.move_type2 * 2 + this.move_type3 * 1

      const s_id = sessionStorage.getItem('session_id')
      const s_uid = parseInt(
        sessionStorage.getItem('pub_permissions').substr(1)
      )
      const url = `https://www.bamb.asia/suppliers/dispatchInfo`
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
        },
        body: `sessionid=${s_id}&supplier_id=${s_uid}&move_type=${move_type}&begin_date=${begin_date}&end_date=${end_date}`,
      };

      await fetch(url, config)
        .then((res) => res.text())
        .then((rep) => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          if (rep.errno === "1") {
            this.dipData = rep.records
            this.dispatch_options = this.dispatch.reduce((acc,cur)=>{
              if(!acc.find(option=>option.label === this.move_type_dispatch_table[cur.type])){
                acc.push({label:this.move_type_dispatch_table[cur.type],checked:true})
              }
              return acc
            },[])
            this.warehouse_options = this.warehouse.reduce((acc,cur)=>{
              if(!acc.find(option=>option.label === this.move_type_warehouse_table[cur.type])){
                acc.push({label:this.move_type_warehouse_table[cur.type],checked:true})
              }
              return acc
            },[])
            this.retail_options = this.retail.reduce((acc,cur)=>{
              if(!acc.find(option=>option.label === this.move_type_retail_table[cur.type])){
                acc.push({label:this.move_type_retail_table[cur.type],checked:true})
              }
              return acc
            },[])
          }
        })
    },
    exportExcelHandler(){
      const data = [...this.dispatch,...this.warehouse,...this.retail]
      if(data.length===0) return 

      const workbook = new Excel.Workbook()
      const worksheet1 = workbook.addWorksheet()
      const worksheet2 = workbook.addWorksheet()

      worksheet1.addRow(['出/入庫','日付','移動類型','移動目的地','移動標題','品番','良','不良'])
      for (const item of data) {
        const content = item.content.map(d=>d)
        const first_content = content.shift()
        worksheet1.addRow([item.dip_type,item.shipping_date,item.move_type,item.move_target,item.title,first_content.code,first_content.qualified_quantity,first_content.defective_quantity])
        for (const con of content) {
          worksheet1.addRow(['','','','',item.title,con.code,con.qualified_quantity,con.defective_quantity])
        }
      }
      worksheet2.addRows(this.headerlist)
      workbook.xlsx.writeBuffer().then(data => {
        let blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        saveAs(blob, `在庫移動.xlsx`)
      })
    },
    getSetState(args) {
      if(this.stateList.length ===0){
        this.stateList = args //init for first time
        // Message.success('Get state list success')
      }
      else{
        //modify single set
        const index = this.stateList.findIndex(item=>item.product_code === args.product_code)
        this.$set(this.stateList,index,args)
        // Message.success('update state success')
      }
    },
    chosenType(code){
      let color = 'black' 
      const target = this.stateList.find(d=>d.product_code === code)
      if(target===undefined) return color

      switch(target.product_type){
        case '2':
          color = '#5adb15' 
          break
        case '3':
          color = '#0aa7a9' 
          break
        case '4':
          color = '#cb6c0e' 
          break
        case '5':
          color = '#d6425b' 
          break
      }
      return color
    },
    filterTag(value, row) {
      return row.dip_type === value;
    }
  },
  mounted(){
    // this.getSalesData()
    this.$eventBus.$on('getSetState', this.getSetState, this)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
